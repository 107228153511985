import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faTwitter,
  faGithub,
  faWhatsapp,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Ensure you have the correct path to your CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Contact Information Section */}
        <div className="footer-section">
          <h3>Contact Information</h3>
          <p>Mr. Samadhan Devkar</p>
          
          <p>M-Tech: Embedded Control System</p>
          <p>BE: Electrical Engineer</p>
          <p>Email: <a href="mailto:Samadhandevkar1995@gmail.com">Samadhandevkar1995@gmail.com</a></p>
        </div>

        {/* Quick Links Section */}
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/courses">Courses</a></li>
            <li><a href="/research">Research</a></li>
            <li><a href="/mcqs">MCQs</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/download">Download</a></li>
          </ul>
        </div>

        {/* Social Media Links Section */}
        <div className="footer-section">
          <h3>Share Me</h3>
          <ul className="social-links">
            <li>
              <a
                href="https://www.linkedin.com/sharing/share-offsite/?url=https://electricalthought.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on LinkedIn"
              >
                <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/intent/tweet?text=Check%20out%20this%20website&url=https://electricalthought.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on Twitter"
              >
                <FontAwesomeIcon icon={faTwitter} /> Twitter
              </a>
            </li>
            <li>
              <a
                href="https://github.com/your-github-username"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="GitHub Profile"
              >
                <FontAwesomeIcon icon={faGithub} /> GitHub
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/?text=Check%20out%20this%20website%20https://electricalthought.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Share on WhatsApp"
              >
                <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/your-instagram-username"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram Profile"
              >
                <FontAwesomeIcon icon={faInstagram} /> Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} electricalthought.com - All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
