import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { doc, setDoc, collection } from 'firebase/firestore';
import { db } from '../firebase'; // Firestore config
import { auth } from '../firebase'; // Firebase auth for user info

const useUserHistory = () => {
  const location = useLocation();

  useEffect(() => {
    const trackHistory = async () => {
      const user = auth.currentUser;
      if (user) {
        const historyDoc = doc(collection(db, 'userHistories'), user.uid);
        await setDoc(
          historyDoc,
          {
            history: {
              [new Date().toISOString()]: location.pathname,
            },
          },
          { merge: true }
        );
      }
    };

    trackHistory();
  }, [location]);
};

export default useUserHistory;
