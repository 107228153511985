import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firestore instance
import { collection, doc, setDoc, getDocs, deleteDoc, writeBatch } from 'firebase/firestore';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import * as XLSX from 'xlsx'; // For Excel file parsing
import mammoth from 'mammoth'; // For Word file parsing

const AdminMCQEditor = () => {
  const [mcqs, setMcqs] = useState([]);
  const [question, setQuestion] = useState(''); // Store rich text for question
  const [options, setOptions] = useState(['', '', '', '']);
  const [answer, setAnswer] = useState('');
  const [explanation, setExplanation] = useState('');
  const [subject, setSubject] = useState('');
  const [filterSubject, setFilterSubject] = useState('');
  const [selectedMCQ, setSelectedMCQ] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const subjects = ['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System', 'Aptitude'];

  useEffect(() => {
    const fetchMCQs = async () => {
      try {
        const mcqSnapshot = await getDocs(collection(db, 'mcqs'));
        setMcqs(mcqSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        setError('Failed to load MCQs.');
        console.error('Error fetching MCQs:', error);
      }
    };

    fetchMCQs();
  }, []);

  const handleSaveMCQ = async () => {
    if (!question || options.includes('') || !answer || !subject) {
      alert('Please fill all fields correctly.');
      return;
    }

    setLoading(true);
    try {
      const mcqRef = selectedMCQ
        ? doc(db, 'mcqs', selectedMCQ.id)
        : doc(collection(db, 'mcqs'));

      await setDoc(mcqRef, {
        question, // Save the rich text content
        options,
        answer,
        explanation,
        subject,
        createdAt: new Date(),
      });
      alert('MCQ saved successfully!');

      // Clear input fields
      setQuestion('');
      setOptions(['', '', '', '']);
      setAnswer('');
      setExplanation('');
      setSubject('');
      setSelectedMCQ(null);

      // Refetch MCQs after save
      const mcqSnapshot = await getDocs(collection(db, 'mcqs'));
      setMcqs(mcqSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error('Error saving MCQ:', error);
      alert('Error saving MCQ. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    try {
      let questions = [];
      if (file.name.endsWith('.xlsx')) {
        questions = await parseExcel(file);
      } else if (file.name.endsWith('.docx')) {
        questions = await parseWord(file);
      } else {
        alert('Unsupported file type. Please upload .xlsx or .docx files.');
        return;
      }
      await saveQuestionsToDB(questions);
    } catch (error) {
      console.error('Error processing file:', error);
      alert('Failed to process the file.');
    } finally {
      setLoading(false);
    }
  };

  const parseExcel = async (file) => {
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
    return sheetData.map((row) => ({
      question: row.Question,
      options: [row.Option1, row.Option2, row.Option3, row.Option4],
      answer: row.Answer,
      explanation: row.Explanation,
      subject: row.Subject,
    }));
  };

  const parseWord = async (file) => {
    const data = await file.arrayBuffer();
    const result = await mammoth.extractRawText({ arrayBuffer: data });
    const lines = result.value.split('\n').filter(Boolean);
    const questions = [];
    let currentQuestion = {};

    lines.forEach((line) => {
      if (line.startsWith('Question:')) {
        if (currentQuestion.question) questions.push(currentQuestion);
        currentQuestion = { question: line.replace('Question:', '').trim() };
      } else if (line.startsWith('Options:')) {
        currentQuestion.options = line.replace('Options:', '').trim().split(',');
      } else if (line.startsWith('Answer:')) {
        currentQuestion.answer = line.replace('Answer:', '').trim();
      } else if (line.startsWith('Explanation:')) {
        currentQuestion.explanation = line.replace('Explanation:', '').trim();
      } else if (line.startsWith('Subject:')) {
        currentQuestion.subject = line.replace('Subject:', '').trim();
      }
    });

    if (currentQuestion.question) questions.push(currentQuestion);
    return questions;
  };

  const saveQuestionsToDB = async (questions) => {
    try {
      const batch = writeBatch(db);
      const mcqCollection = collection(db, 'mcqs');
      questions.forEach((question) => {
        const docRef = doc(mcqCollection);
        batch.set(docRef, question);
      });
      await batch.commit();
      alert('Questions uploaded successfully!');
      const fetchMCQs = async (setMcqs) => {
        try {
          const mcqSnapshot = await getDocs(collection(db, 'mcqs'));
          setMcqs(mcqSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
          console.error('Error fetching MCQs:', error);
        }
      };
      
    } catch (error) {
      console.error('Error saving questions:', error);
      alert('Error saving questions. Please try again.');
    }
  };

  const handleDeleteMCQ = async (id) => {
    if (!window.confirm('Are you sure you want to delete this MCQ?')) return;
    try {
      await deleteDoc(doc(db, 'mcqs', id));
      setMcqs(mcqs.filter((mcq) => mcq.id !== id));
    } catch (error) {
      console.error('Error deleting MCQ:', error);
      alert('Error deleting MCQ.');
    }
  };

  const handleEditMCQ = (mcq) => {
    setSelectedMCQ(mcq);
    setQuestion(mcq.question);
    setOptions(mcq.options);
    setAnswer(mcq.answer);
    setExplanation(mcq.explanation || '');
    setSubject(mcq.subject);
  };

  const handleFilterChange = (e) => {
    setFilterSubject(e.target.value);
  };

  const filteredMcqs = mcqs.filter((mcq) => !filterSubject || mcq.subject === filterSubject);

  return (
    <div>
      <h3>{selectedMCQ ? 'Edit MCQ' : 'Add New MCQ'}</h3>

      <select value={subject} onChange={(e) => setSubject(e.target.value)}>
        <option value="">Select Subject</option>
        {subjects.map((subj, index) => (
          <option key={index} value={subj}>
            {subj}
          </option>
        ))}
      </select>

      {/* Rich text editor for question */}
      <ReactQuill
        value={question}
        onChange={setQuestion}
        placeholder="Enter the MCQ question here..."
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
        }}
      />

      <div>
        {options.map((opt, index) => (
          <input
            key={index}
            type="text"
            value={opt}
            onChange={(e) => {
              const newOptions = [...options];
              newOptions[index] = e.target.value;
              setOptions(newOptions);
            }}
            placeholder={`Option ${index + 1}`}
            />
          ))}
        </div>
  
        <input
          type="text"
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Correct Answer"
        />
  
        <textarea
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
          placeholder="Explanation"
        />
  
        <button onClick={handleSaveMCQ} disabled={loading}>
          {selectedMCQ ? 'Update MCQ' : 'Add MCQ'}
        </button>
  
        <h3>Bulk Upload MCQs</h3>
        <input type="file" accept=".xlsx, .docx" onChange={handleFileUpload} />
        {loading && <p>Processing file...</p>}
  
        {error && <p style={{ color: 'red' }}>{error}</p>}
  
        <h2>Filter MCQs by Subject</h2>
        <select value={filterSubject} onChange={handleFilterChange}>
          <option value="">All Subjects</option>
          {subjects.map((subj, index) => (
            <option key={index} value={subj}>
              {subj}
            </option>
          ))}
        </select>
  
        <h2>Existing MCQs</h2>
        <ul>
          {filteredMcqs.map((mcq, index) => (
            <li key={mcq.id}>
              <h4>
                {index + 1}. <span dangerouslySetInnerHTML={{ __html: mcq.question }} /> ({mcq.subject})
              </h4>
              <ul>
                {mcq.options.map((opt, i) => (
                  <li key={i}>
                    {opt}
                    <button
                      onClick={() => alert(`Answer: ${mcq.answer}\nExplanation: ${mcq.explanation}`)}
                      style={{ marginLeft: '10px' }}
                    >
                      Show Answer
                    </button>
                  </li>
                ))}
              </ul>
              <button onClick={() => handleEditMCQ(mcq)}>Edit</button>
              <button onClick={() => handleDeleteMCQ(mcq.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export default AdminMCQEditor;
  
