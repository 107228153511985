import React, { useState, useEffect } from 'react'; 
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Your Firebase config
import './MCQsBySubject.css'; // Import your CSS file

const MCQsBySubject = () => {
  const { subjectName } = useParams(); // Get subject from URL
  const [mcqs, setMcqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMCQ, setSelectedMCQ] = useState(null); // Track selected MCQ
  const [selectedOption, setSelectedOption] = useState({}); // Track selected options for each MCQ

  useEffect(() => {
    const fetchMcqs = async () => {
      try {
        const mcqCollection = collection(db, 'mcqs');
        const q = query(mcqCollection, where('subject', '==', subjectName));
        const mcqSnapshot = await getDocs(q);
        const mcqList = mcqSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setMcqs(mcqList);
      } catch (err) {
        setError('Failed to fetch MCQs');
        console.error('Error fetching MCQs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchMcqs();
  }, [subjectName]);

  const handleClickOption = (mcqId, option) => {
    setSelectedOption((prev) => ({ ...prev, [mcqId]: option })); // Set selected option for each MCQ
    setSelectedMCQ(mcqId);
  };

  const getOptionColor = (mcqId, option, answer) => {
    if (selectedOption[mcqId] === undefined) return 'black'; // Default color
    if (option === answer) return 'green'; // Correct answer
    if (option === selectedOption[mcqId]) return 'red'; // Incorrect answer
    return 'black'; // Default color for non-selected options
  };

  if (loading) {
    return <div className="loading">Loading MCQs for {subjectName}...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="mcqs-by-subject">
      <h1>{subjectName} MCQs</h1>
      {mcqs.length === 0 ? (
        <p>No MCQs found for this subject.</p>
      ) : (
        mcqs.map((mcq, index) => (
          <div key={mcq.id} className="mcq-item">
            <h2 className="mcq-question">
              {index + 1}.&nbsp; {/* Add a non-breaking space here */}
              <span dangerouslySetInnerHTML={{ __html: mcq.question }} />
            </h2> 

            <ul className="options-list">
              {mcq.options.map((option, optionIndex) => (
                <li
                  key={optionIndex}
                  className="option-item"
                  onClick={() => handleClickOption(mcq.id, option)} // Handle click on option
                  style={{ cursor: 'pointer', color: getOptionColor(mcq.id, option, mcq.answer) }} // Conditional color
                >
                  <input
                    type="checkbox"
                    checked={selectedOption[mcq.id] === option}
                    readOnly
                  />
                  {option}
                </li>
              ))}
            </ul>

            {/* Conditionally display answer and explanation if this MCQ is selected */}
            {selectedMCQ === mcq.id && (
              <div className="mcq-details">
                <p><strong>Answer:</strong> {mcq.answer}</p>
                {mcq.explanation && (
                  <p><strong>Explanation:</strong> {mcq.explanation}</p>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default MCQsBySubject;
