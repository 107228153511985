// Layout.js
import React from 'react';
import Navbar from '../components/Navbar';
import RightSidebar from '../components/RightSidebar';
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  return (
    <div className="App">
      <Navbar />
      <div className="content-wrapper">
        <div className="main-content">
          <Outlet /> {/* Nested routes will render here */}
        </div>
        <RightSidebar />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
