
import React from 'react';
import './Home.css'; // Import the CSS file

const Home = () => {
  return (
    <div className="home-container">
      {/* Hero Section */}
      <header className="hero-section">
        <h1 className="hero-title">Welcome to Electrical Thought</h1>
        <p className="hero-subtitle">Explore the world of electrical engineering and innovation.</p>
      </header>

      {/* About Section */}
      <section className="about-section">
        <h2 className="section-title">About Electrical Engineering</h2>
        <p>
          Electrical engineering is a dynamic and multifaceted field that focuses on the study and application of electricity, electronics, and electromagnetism. It encompasses a broad range of topics, from the design of electrical circuits and systems to the development of new technologies for power generation, transmission, and distribution.
        </p>
      </section>

      {/* Applications Section */}
      <section className="applications-section">
        <h2 className="section-title">Applications</h2>
        <p>
          Electrical engineers work on a variety of projects, including the creation of electrical devices, control systems, telecommunications infrastructure, and renewable energy solutions. Their work is integral to modern society, impacting everything from the reliability of the electrical grid to the advancement of consumer electronics and innovative technologies.
        </p>
      </section>

      {/* Vision Section */}
      <section className="vision-section">
        <h2 className="section-title">Our Vision</h2>
        <p>
          By combining theoretical knowledge with practical skills, electrical engineers address complex challenges and drive technological progress, making significant contributions to both industry and daily life.
        </p>
      </section>
    </div>
  );
};

export default Home;