import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Firebase config
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML
import './Research.css'; // Import CSS for styling

const Research = () => {
  const [researchPapers, setResearchPapers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPaperId, setSelectedPaperId] = useState(null);

  useEffect(() => {
    const fetchResearchPapers = async () => {
      setLoading(true);
      setError(null);
      try {
        const researchCollection = collection(db, 'research');
        const researchSnapshot = await getDocs(researchCollection);
        const researchList = researchSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setResearchPapers(researchList);
      } catch (err) {
        console.error('Error fetching research papers:', err);
        setError('Failed to load research papers. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchResearchPapers();
  }, []);

  const handleTitleClick = (id) => {
    setSelectedPaperId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="research-container">
      {loading && <div className="loading">Loading research papers...</div>}
      {error && <div className="error">{error}</div>}
      {!loading && !error && researchPapers.length === 0 && (
        <div className="no-research-papers">No research papers available</div>
      )}
      {!loading && !error && researchPapers.length > 0 && (
        researchPapers.map((paper, index) => (
          <div key={paper.id} className="research-paper">
            <h2
              className={`research-title ${
                selectedPaperId === paper.id ? 'active' : ''
              }`}
              onClick={() => handleTitleClick(paper.id)}
            >
              {index + 1}. {paper.title}
            </h2>
            {selectedPaperId === paper.id && (
              <div
                className="research-content"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(paper.content),
                }}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default Research;
