import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Firebase configuration
import './QuizzesBySubject.css'; // Import your custom styles

const QuizzesBySubject = () => {
  const { subjectName } = useParams(); // Get the subject name from the URL
  const [quizzes, setQuizzes] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const quizCollection = collection(db, 'quizzes');
        const subjectQuery = query(quizCollection, where('subject', '==', subjectName));
        const quizSnapshot = await getDocs(subjectQuery);
        const quizList = quizSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuizzes(quizList);
      } catch (err) {
        setError('Failed to fetch quizzes.');
        console.error('Error fetching quizzes:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchQuizzes();
  }, [subjectName]);

  const handleOptionClick = (quizId, option) => {
    setSelectedOption((prev) => ({
      ...prev,
      [quizId]: option,
    }));
  };

  const getOptionClass = (quizId, option, correctAnswer) => {
    if (!selectedOption[quizId]) return '';
    if (option === correctAnswer) return 'correct';
    if (option === selectedOption[quizId]) return 'incorrect';
    return '';
  };

  if (loading) return <p>Loading quizzes for {subjectName}...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="quizzes-by-subject">
      <h1>{subjectName} Quizzes</h1>
      {quizzes.length > 0 ? (
        quizzes.map((quiz, index) => (
          <div key={quiz.id} className="quiz-item">
            <h2>
  {index + 1}. {quiz.question.replace(/^<p>/, '').replace(/<\/p>$/, '')}
</h2>
            <p>Marks: {quiz.marks} | Time Limit: {quiz.timeLimit} minutes</p>
            <ul className="quiz-options">
              {quiz.options.map((option, i) => (
                <li
                  key={i}
                  className={`quiz-option ${getOptionClass(quiz.id, option, quiz.answer)}`}
                  onClick={() => handleOptionClick(quiz.id, option)}
                >
                  {option}
                </li>
              ))}
            </ul>
            {selectedOption[quiz.id] && (
              <div className="quiz-answer">
                <p>
                  <strong>Correct Answer:</strong> {quiz.answer}
                </p>
                {quiz.explanation && <p><strong>Explanation:</strong> {quiz.explanation}</p>}
              </div>
            )}
          </div>
        ))
      ) : (
        <p>No quizzes available for this subject.</p>
      )}
    </div>
  );
};

export default QuizzesBySubject;
