// RightSidebar.js
import React, { useEffect } from 'react';
import './RightSidebar.css';

const RightSidebar = () => {
  useEffect(() => {
    // Load Google Ads script when the component mounts
    const loadAds = () => {
      if (window && window.adsbygoogle) {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("Ad load error:", e);
        }
      } else {
        console.warn("adsbygoogle is not defined. Ensure Google Ads script is loaded correctly.");
      }
    };

    // Ensure Google Ads script is loaded
    const scriptId = 'google-ads-script';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.async = true;
      script.onload = loadAds;
      document.body.appendChild(script);
    } else {
      loadAds();
    }
  }, []);

  return (
    <div className="right-sidebar">
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-9745428228335345"
           data-ad-slot="6923937002"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
};

export default RightSidebar;
