import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { collection, doc, setDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AdminBlogEditor.css';

const AdminBlogEditor = () => {
  const [blogs, setBlogs] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [mediaUrl, setMediaUrl] = useState('');
  const [mediaType, setMediaType] = useState('image'); // 'image' or 'video'
  const [loading, setLoading] = useState(false);

  const subjects = ['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System'];

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const blogSnapshot = await getDocs(collection(db, 'blogs'));
        setBlogs(
          blogSnapshot.docs.map((doc, index) => ({
            id: doc.id,
            serialNo: index + 1,
            ...doc.data(),
          }))
        );
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  const resetForm = () => {
    setTitle('');
    setContent('');
    setSubject('');
    setMediaUrl('');
    setMediaType('image');
    setSelectedBlog(null);
  };

  const handleMediaUpload = async (file) => {
    try {
      setLoading(true);
      const mediaRef = ref(storage, `${mediaType}s/${file.name}`);
      await uploadBytes(mediaRef, file);
      const url = await getDownloadURL(mediaRef);
      setMediaUrl(url);
    } catch (error) {
      console.error('Error uploading media:', error);
      alert('Media upload failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveBlog = async () => {
    if (!title || !content || !subject) {
      alert('All fields are required.');
      return;
    }

    try {
      setLoading(true);
      const blogRef = selectedBlog
        ? doc(db, 'blogs', selectedBlog.id)
        : doc(collection(db, 'blogs'));
      await setDoc(blogRef, {
        title,
        content,
        subject,
        mediaUrl,
        mediaType,
        createdAt: new Date(),
      });
      alert('Blog saved successfully!');
      resetForm();
      const blogSnapshot = await getDocs(collection(db, 'blogs'));
      setBlogs(
        blogSnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }))
      );
    } catch (error) {
      alert('Failed to save the blog. Please try again.');
      console.error('Error saving blog:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteBlog = async (id) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, 'blogs', id));
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
      alert('Blog deleted successfully!');
    } catch (error) {
      alert('Failed to delete the blog. Please try again.');
      console.error('Error deleting blog:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-blog-editor">
      <h3>{selectedBlog ? 'Edit Blog' : 'Add New Blog'}</h3>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Blog Title"
        className="blog-input"
      />
      <ReactQuill
        value={content}
        onChange={setContent}
        placeholder="Write your blog content here..."
        theme="snow"
        className="blog-editor"
      />
      <select
        value={subject}
        onChange={(e) => setSubject(e.target.value)}
        className="blog-select"
      >
        <option value="" disabled>
          Select Subject
        </option>
        {subjects.map((subj) => (
          <option key={subj} value={subj}>
            {subj}
          </option>
        ))}
      </select>
      <select
        value={mediaType}
        onChange={(e) => setMediaType(e.target.value)}
        className="media-type-select"
      >
        <option value="image">Image</option>
        <option value="video">Video</option>
      </select>
      <input
        type="file"
        accept={mediaType === 'image' ? 'image/*' : 'video/*'}
        onChange={(e) => {
          const file = e.target.files[0];
          handleMediaUpload(file);
        }}
        className="blog-file-input"
      />
      {mediaUrl && (
        mediaType === 'image' ? (
          <img src={mediaUrl} alt="Uploaded preview" className="blog-preview-media" />
        ) : (
          <video controls className="blog-preview-media">
            <source src={mediaUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      )}
      <button onClick={handleSaveBlog} disabled={loading} className="blog-save-button">
        {loading ? 'Saving...' : selectedBlog ? 'Update Blog' : 'Add Blog'}
      </button>

      <h2>Existing Blogs</h2>
      <ul className="blog-list">
        {blogs.map((blog) => (
          <li key={blog.id} className="blog-item">
            <div>
              <span className="serial-no">{blog.serialNo}. </span>
              <h4>{blog.title} ({blog.subject})</h4>
              <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              {blog.mediaType === 'image' && blog.mediaUrl && (
                <img src={blog.mediaUrl} alt={blog.title} className="blog-list-media" />
              )}
              {blog.mediaType === 'video' && blog.mediaUrl && (
                <video controls className="blog-list-media">
                  <source src={blog.mediaUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
              <button
                onClick={() => {
                  setSelectedBlog(blog);
                  setTitle(blog.title);
                  setContent(blog.content);
                  setSubject(blog.subject);
                  setMediaUrl(blog.mediaUrl);
                  setMediaType(blog.mediaType);
                }}
                className="blog-edit-button"
              >
                Edit
              </button>
              <button
                onClick={() => handleDeleteBlog(blog.id)}
                className="blog-delete-button"
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminBlogEditor;
