import React, { useState, useEffect } from 'react';
import { collection, doc, setDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase'; // Adjust import paths
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './AdminCoursesEditor.css';

const AdminCoursesEditor = () => {
  const [courses, setCourses] = useState([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch courses from Firestore
  const fetchCourses = async () => {
    setLoading(true);
    setError(null);

    try {
      const coursesCollection = collection(db, 'courses');
      const courseSnapshot = await getDocs(coursesCollection);

      if (courseSnapshot.empty) {
        console.log('No courses found');
        setCourses([]);
        return;
      }

      const coursesList = courseSnapshot.docs.map((doc, index) => ({
        id: doc.id,
        serialNo: index + 1,
        ...doc.data(),
      }));
      setCourses(coursesList);
    } catch (error) {
      console.error('Error fetching courses:', error);
      setError('Failed to load courses.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses(); // Call fetchCourses on component mount
  }, []);

  const handleSaveCourse = async () => {
    if (!title || !content) {
      alert('Please fill in both the title and content.');
      return;
    }

    try {
      setLoading(true);
      const courseRef = selectedCourse
        ? doc(db, 'courses', selectedCourse.id)
        : doc(collection(db, 'courses'));

      await setDoc(courseRef, {
        title: title.trim(),
        content: content.trim(),
        updatedAt: new Date(),
      });

      alert(selectedCourse ? 'Course updated successfully!' : 'Course added successfully!');
      resetForm();
      await fetchCourses(); // Re-fetch courses after saving
    } catch (error) {
      console.error('Error saving course:', error);
      alert('Failed to save course.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCourse = async (id) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, 'courses', id));
      alert('Course deleted successfully!');
      setCourses(courses.filter((course) => course.id !== id));
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Failed to delete course.');
    } finally {
      setLoading(false);
    }
  };

  const handleEditCourse = (course) => {
    setTitle(course.title);
    setContent(course.content);
    setSelectedCourse(course);
  };

  const resetForm = () => {
    setTitle('');
    setContent('');
    setSelectedCourse(null);
  };

  const handleImageUpload = async (file) => {
    if (!file) return;

    try {
      const imageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(imageRef, file);
      const imageUrl = await getDownloadURL(imageRef);
      setContent((prevContent) => `${prevContent}<img src="${imageUrl}" alt="Uploaded image" />`);
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('Failed to upload image.');
    }
  };

  return (
    <div className="admin-courses-editor">
      <h3>{selectedCourse ? 'Edit Course' : 'Add New Course'}</h3>
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Course Title"
        className="course-title-input"
      />
      <ReactQuill
        value={content}
        onChange={setContent}
        placeholder="Write course content here..."
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            [{ align: [] }],
            ['clean'],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'list',
          'bullet',
          'link',
          'image',
          'align',
        ]}
        theme="snow"
      />
      <input
        type="file"
        accept="image/*"
        onChange={(e) => handleImageUpload(e.target.files[0])}
        className="file-upload-input"
      />
      <button onClick={handleSaveCourse} disabled={loading}>
        {loading ? 'Saving...' : selectedCourse ? 'Update Course' : 'Add Course'}
      </button>

      <h2>Existing Courses</h2>
      {loading ? (
        <p>Loading courses...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <ul className="course-list">
          {courses.length === 0 ? (
            <li>No courses available</li>
          ) : (
            courses.map((course) => (
              <li key={course.id}>
                <h4>{course.serialNo}. {course.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: course.content }} />
                <button onClick={() => handleEditCourse(course)}>Edit</button>
                <button onClick={() => handleDeleteCourse(course.id)}>Delete</button>
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default AdminCoursesEditor;
