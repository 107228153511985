import React, { useState } from 'react';
import AdminBlogEditor from './AdminBlogEditor';
import AdminCoursesEditor from './AdminCoursesEditor';
import AdminResearchEditor from './AdminResearchEditor';
import AdminMCQEditor from './AdminMCQEditor';
import AdminQuizEditor from './AdminQuizEditor';
import ManageFiles from './ManageFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const [searchQuery, setSearchQuery] = useState(''); // Add search query state

  // Section Components
  const blogSection = { title: 'Manage Blog Posts', component: <AdminBlogEditor /> };
  const coursesSection = { title: 'Manage Courses', component: <AdminCoursesEditor /> };
  const researchSection = { title: 'Manage Research', component: <AdminResearchEditor /> };
  const mcqSection = { title: 'Manage MCQs', component: <AdminMCQEditor /> };
  const quizSection = { title: 'Manage Quizzes', component: <AdminQuizEditor /> };
  const filesSection = { title: 'Manage Files', component: <ManageFiles /> };

  // Sections to manage (with titles)
  const sections = [
    blogSection,
    coursesSection,
    researchSection,
    mcqSection,
    quizSection,
    filesSection,
  ];

  // Filter sections based on the search query
  const filteredSections = sections.filter(section =>
    section.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-header">Admin Dashboard</h1>

      {/* Search bar with search icon */}
      <div className="search-bar">
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
        <input
          type="text"
          placeholder="Search..."
          className="search-input"
          value={searchQuery} // Bind input value to state
          onChange={(e) => setSearchQuery(e.target.value)} // Update state when typing
        />
      </div>

      {/* Render filtered sections */}
      {filteredSections.length > 0 ? (
        filteredSections.map((section, index) => (
          <div className="section" key={index}>
            <h2 className="section-title">{section.title}</h2>
            <div className="section-content">{section.component}</div>
          </div>
        ))
      ) : (
        <p className="no-matching-sections">No matching sections found.</p> // Display message if no matches
      )}
    </div>
  );
};

export default AdminDashboard;
