import React, { useState, useEffect } from 'react';
import { storage, firestore } from '../firebase';
import { ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, deleteDoc, getDocs, collection } from 'firebase/firestore';
import './ManageFiles.css';

const ManageFiles = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subject, setSubject] = useState('');
  const [editingFileId, setEditingFileId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    setLoading(true);
    setError(null);
    try {
      const fileDocs = await getDocs(collection(firestore, 'files'));
      const fileData = fileDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setFiles(fileData);
    } catch (error) {
      console.error("Error fetching files:", error);
      setError('Failed to fetch files. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async () => {
    if (!file || !title || !description || !subject) {
      alert('All fields are required.');
      return;
    }
    setLoading(true);
    try {
      const fileRef = ref(storage, `files/${file.name}`);
      await uploadBytes(fileRef, file);
      const fileDocRef = doc(firestore, 'files', file.name);
      await setDoc(fileDocRef, {
        title,
        description,
        subject,
        url: await getDownloadURL(fileRef),
      });
      alert('File uploaded successfully!');
      fetchFiles();
      resetForm();
    } catch (error) {
      console.error("Error uploading file:", error);
      alert('Failed to upload file.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileDelete = async (fileId, fileName) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;
    setLoading(true);
    try {
      const fileRef = ref(storage, `files/${fileName}`);
      await deleteObject(fileRef);
      await deleteDoc(doc(firestore, 'files', fileId));
      alert('File deleted successfully!');
      fetchFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
      alert('Failed to delete file.');
    } finally {
      setLoading(false);
    }
  };

  const handleFileEdit = (file) => {
    setEditingFileId(file.id);
    setTitle(file.title);
    setDescription(file.description);
    setSubject(file.subject);
  };

  const handleFileUpdate = async () => {
    if (!editingFileId) return;
    setLoading(true);
    try {
      const fileDocRef = doc(firestore, 'files', editingFileId);
      await setDoc(fileDocRef, {
        title,
        description,
        subject,
      }, { merge: true });
      alert('File updated successfully!');
      fetchFiles();
      resetForm();
    } catch (error) {
      console.error("Error updating file:", error);
      alert('Failed to update file.');
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFile(null);
    setTitle('');
    setDescription('');
    setSubject('');
    setEditingFileId('');
  };

  return (
    <div className="manage-files">
      <h1>Manage Files</h1>

      <div className="file-form">
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="file-input"
        />
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="title-input"
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="description-input"
        />
        <input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="subject-input"
        />
        {editingFileId ? (
          <button onClick={handleFileUpdate} disabled={loading}>
            {loading ? 'Updating...' : 'Update'}
          </button>
        ) : (
          <button onClick={handleFileUpload} disabled={loading}>
            {loading ? 'Uploading...' : 'Upload'}
          </button>
        )}
      </div>

      {loading ? (
        <p>Loading files...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <ul className="file-list">
          {files.map((file, index) => (
            <li key={file.id} className="file-item">
              <span>{index + 1}. </span>
              <a href={file.url} download>{file.title || file.url.split('/').pop()}</a>
              <p>{file.description}</p>
              <p><strong>Subject:</strong> {file.subject}</p>
              <button
                onClick={() => handleFileDelete(file.id, file.url.split('/').pop())}
                disabled={loading}
              >
                Delete
              </button>
              <button onClick={() => handleFileEdit(file)} disabled={loading}>
                Edit
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ManageFiles;
