import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Your Firebase config
import './Quiz.css';

const Quiz = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [filterSubject, setFilterSubject] = useState('');

  const subjects = ['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System'];

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const quizCollection = collection(db, 'quizzes');
        const quizSnapshot = await getDocs(quizCollection);
        const quizList = quizSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setQuizzes(quizList);
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      }
    };

    fetchQuizzes();
  }, []);

  const handleFilterChange = (e) => {
    setFilterSubject(e.target.value);
  };

  const filteredQuizzes = quizzes.filter((quiz) => !filterSubject || quiz.subject === filterSubject);

  return (
    <div>
      <h2>Filter Quizzes by Subject</h2>
      <select value={filterSubject} onChange={handleFilterChange}>
        <option value="">All Subjects</option>
        {subjects.map((subject, index) => (
          <option key={index} value={subject}>
            {subject}
          </option>
        ))}
      </select>

      <h2>Quizzes</h2>
      {filteredQuizzes.length > 0 ? (
        filteredQuizzes.map((quiz, index) => (
          <div key={quiz.id} style={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
            <h3>
              {index + 1}. {quiz.question}
            </h3>
            <ol>
              {quiz.options.map((option, optionIndex) => (
                <li key={optionIndex}>{option}</li>
              ))}
            </ol>
            <button
              onClick={() => alert(`Correct Answer: ${quiz.answer}\nExplanation: ${quiz.explanation || 'No explanation provided.'}`)}
            >
              Show Answer
            </button>
          </div>
        ))
      ) : (
        <p>No quizzes available for the selected subject.</p>
      )}
    </div>
  );
};

export default Quiz;
