import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Courses from './pages/Courses';
import CourseDetail from './pages/CourseDetail';
import Research from './pages/Research';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import MCQs from './pages/MCQs';
import MCQsBySubject from './pages/MCQsBySubject';
import Contact from './pages/Contact';
import Download from './pages/Download';
import Login from './pages/Login';
import Signup from './pages/Signup';
import AdminDashboard from './pages/AdminDashboard';
import Quiz from './pages/Quiz';
import QuizzesBySubject from './pages/QuizzesBySubject';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import useUserHistory from './hooks/useUserHistory';

function App() {
  return (
    <Router>
      {/* Initialize user history tracking */}
      <HistoryTracker />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="courses" element={<Courses />} />
          <Route path="courses/:subject" element={<CourseDetail />} />
          <Route path="research" element={<Research />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:subject" element={<BlogDetail />} />
          <Route path="mcqs" element={<MCQs />} />
          <Route path="mcqs/:subjectName" element={<MCQsBySubject />} />
          <Route path="Quiz" element={<Quiz />} />
          <Route path="Quiz/:subjectName" element={<QuizzesBySubject />} />
          <Route path="contact" element={<Contact />} />
          <Route path="download" element={<Download />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

function HistoryTracker() {
  useUserHistory(); // Hook to track user history
  return null; // This component does not render anything
}

export default App;
