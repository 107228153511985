import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { getDocs, collection } from 'firebase/firestore';
import './Download.css';

const Download = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null); // Add error state

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const fileDocs = await getDocs(collection(firestore, 'files'));
      const fileData = fileDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })); // Include document ID if needed
      setFiles(fileData);
    } catch (error) {
      console.error('Error fetching files:', error);
      setError('Failed to load files. Please try again later.');
    } finally {
      setLoading(false); // Ensure loading state is updated
    }
  };

  return (
    <div className="download-container">
      <h1>Download Files</h1>
      {loading && <p>Loading files...</p>} {/* Show loading message */}
      {error && <p className="error">{error}</p>} {/* Show error message */}
      {!loading && !error && files.length === 0 && <p>No files available for download.</p>} {/* Show message if no files */}
      <ul>
        {files.map((file, index) => (
          <li key={file.id || index}>
            <span>{index + 1}. </span> {/* Serial number */}
            <p>
              <strong>Subject:</strong> {file.subject || 'N/A'}
            </p>
            <a href={file.url} download>
              {file.title || file.url.split('/').pop()}
            </a>
            <p>{file.description || 'No description provided.'}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Download;
