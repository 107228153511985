import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firestore instance
import { collection, doc, setDoc, getDocs, deleteDoc, writeBatch, onSnapshot } from 'firebase/firestore';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import * as XLSX from 'xlsx'; // For Excel file parsing
import mammoth from 'mammoth'; // For Word file parsing
import './AdminQuizEditor.css';

const AdminQuizEditor = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [quizName, setQuizName] = useState('');
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState(['', '', '', '']);
  const [answer, setAnswer] = useState('');
  const [explanation, setExplanation] = useState('');
  const [subject, setSubject] = useState('');
  const [timeLimit, setTimeLimit] = useState(0);
  const [marks, setMarks] = useState(0);
  const [filterSubject, setFilterSubject] = useState('');
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [previewQuiz, setPreviewQuiz] = useState(null);

  const subjects = ['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System', 'Aptitude'];

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'quizzes'), (snapshot) => {
      setQuizzes(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
    return unsubscribe;
  }, []);

  const handleSaveQuiz = async () => {
    if (!quizName || !question || options.includes('') || !answer || !subject || !timeLimit || !marks) {
      alert('Please fill all fields correctly.');
      return;
    }

    setLoading(true);
    try {
      const quizRef = selectedQuiz
        ? doc(db, 'quizzes', selectedQuiz.id)
        : doc(collection(db, 'quizzes'));

      await setDoc(quizRef, {
        quizName: quizName.trim(),
        question: question.trim(),
        options,
        answer,
        explanation,
        subject,
        timeLimit,
        marks,
        createdAt: new Date(),
      });
      alert('Quiz saved successfully!');

      setQuizName('');
      setQuestion('');
      setOptions(['', '', '', '']);
      setAnswer('');
      setExplanation('');
      setSubject('');
      setTimeLimit(0);
      setMarks(0);
      setSelectedQuiz(null);
    } catch (error) {
      console.error('Error saving quiz:', error);
      alert('Error saving quiz. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewQuiz = (quiz) => {
    setPreviewQuiz(quiz);
  };

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const sheetData = quizzes.map((quiz) => ({
      QuizName: quiz.quizName,
      Question: quiz.question,
      Option1: quiz.options[0],
      Option2: quiz.options[1],
      Option3: quiz.options[2],
      Option4: quiz.options[3],
      Answer: quiz.answer,
      Explanation: quiz.explanation,
      Subject: quiz.subject,
      TimeLimit: quiz.timeLimit,
      Marks: quiz.marks,
    }));
    const worksheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quizzes');
    XLSX.writeFile(workbook, 'quizzes.xlsx');
  };

  const filteredQuizzes = quizzes.filter(
    (quiz) =>
      (!filterSubject || quiz.subject === filterSubject) &&
      (quiz.quizName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quiz.question.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div>
      <h3>{selectedQuiz ? 'Edit Quiz' : 'Add New Quiz'}</h3>
      <input
        type="text"
        value={quizName}
        onChange={(e) => setQuizName(e.target.value)}
        placeholder="Quiz Name"
      />

      <select value={subject} onChange={(e) => setSubject(e.target.value)}>
        <option value="">Select Subject</option>
        {subjects.map((subj, index) => (
          <option key={index} value={subj}>
            {subj}
          </option>
        ))}
      </select>

      <ReactQuill
        value={question}
        onChange={setQuestion}
        placeholder="Enter the quiz question here..."
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image', 'video'],
            ['clean'],
          ],
        }}
      />

      <div>
        {options.map((opt, index) => (
          <input
            key={index}
            type="text"
            value={opt}
            onChange={(e) => {
              const newOptions = [...options];
              newOptions[index] = e.target.value;
              setOptions(newOptions);
            }}
            placeholder={`Option ${index + 1}`}
          />
        ))}
      </div>

      <input
        type="text"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
        placeholder="Correct Answer"
      />

      <textarea
        value={explanation}
        onChange={(e) => setExplanation(e.target.value)}
        placeholder="Explanation"
      />

      <input
        type="number"
        value={timeLimit}
        onChange={(e) => setTimeLimit(Number(e.target.value))}
        placeholder="Time Limit (in minutes)"
      />

      <input
        type="number"
        value={marks}
        onChange={(e) => setMarks(Number(e.target.value))}
        placeholder="Marks"
      />

      <button onClick={handleSaveQuiz} disabled={loading}>
        {selectedQuiz ? 'Update Quiz' : 'Add Quiz'}
      </button>

      <h3>Search Quizzes</h3>
      <input
        type="text"
        placeholder="Search by quiz name or question..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <h3>Export Quizzes</h3>
      <button onClick={exportToExcel}>Export to Excel</button>

      <h2>Filter Quizzes by Subject</h2>
      <select value={filterSubject} onChange={(e) => setFilterSubject(e.target.value)}>
        <option value="">All Subjects</option>
        {subjects.map((subj, index) => (
          <option key={index} value={subj}>
            {subj}
          </option>
        ))}
      </select>

      <h2>Existing Quizzes</h2>
      <ul>
        {filteredQuizzes.map((quiz) => (
          <li key={quiz.id}>
            <h4>{quiz.quizName} ({quiz.subject})</h4>
            <button onClick={() => handlePreviewQuiz(quiz)}>Preview</button>
            <button onClick={() => setSelectedQuiz(quiz)}>Edit</button>
            <button onClick={() => deleteDoc(doc(db, 'quizzes', quiz.id))}>Delete</button>
          </li>
        ))}
      </ul>

      {previewQuiz && (
        <div className="quiz-preview-modal">
          <h2>Preview: {previewQuiz.quizName}</h2>
          <p>Question: {previewQuiz.question}</p>
          <ul>
            {previewQuiz.options.map((opt, i) => (
              <li key={i}>{opt}</li>
            ))}
          </ul>
          <p>Answer: {previewQuiz.answer}</p>
          <p>Explanation: {previewQuiz.explanation}</p>
          <button onClick={() => setPreviewQuiz(null)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default AdminQuizEditor;
