import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Firestore instance
import { collection, getDocs } from 'firebase/firestore';

const Quiz = () => {
  const [quizzes, setQuizzes] = useState([]);
  const [filterSubject, setFilterSubject] = useState('');
  const [userAnswers, setUserAnswers] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(300); // Time in seconds (e.g., 300s = 5 mins)

  const subjects = ['Power System', 'Power Electronics', 'Machines', 'Control System', 'Embedded System', 'Aptitude'];

  useEffect(() => {
    const fetchQuizzes = async () => {
      try {
        const quizSnapshot = await getDocs(collection(db, 'quizzes'));
        setQuizzes(quizSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.error('Error fetching quizzes:', error);
      }
    };

    fetchQuizzes();
  }, []);

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => setTimeRemaining((prevTime) => prevTime - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeRemaining === 0) {
      setShowResult(true);
    }
  }, [timeRemaining]);

  const handleAnswerChange = (quizId, option) => {
    setUserAnswers((prevAnswers) => ({ ...prevAnswers, [quizId]: option }));
  };

  const calculateResult = () => {
    let correctCount = 0;

    quizzes.forEach((quiz) => {
      if (userAnswers[quiz.id] === quiz.answer) {
        correctCount++;
      }
    });

    return correctCount;
  };

  const handleSubmit = () => {
    setShowResult(true);
  };

  const filteredQuizzes = quizzes.filter((quiz) => !filterSubject || quiz.subject === filterSubject);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <h1>Quiz</h1>

      {showResult ? (
        <div>
          <h2>Results</h2>
          <p>
            You answered {calculateResult()} out of {filteredQuizzes.length} questions correctly.
          </p>
          <ul>
            {filteredQuizzes.map((quiz) => (
              <li key={quiz.id}>
                <h4>{quiz.question.replace(/^<p>|<\/p>$/g, '')}</h4>
                <p>
                  Your Answer: {userAnswers[quiz.id] || 'Not answered'}
                  <br />
                  Correct Answer: {quiz.answer}
                </p>
                {quiz.explanation && <p>Explanation: {quiz.explanation}</p>}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <h2>Time Remaining: {formatTime(timeRemaining)}</h2>

          <h3>Filter Quizzes by Subject</h3>
          <select value={filterSubject} onChange={(e) => setFilterSubject(e.target.value)}>
            <option value="">All Subjects</option>
            {subjects.map((subj, index) => (
              <option key={index} value={subj}>
                {subj}
              </option>
            ))}
          </select>

          <h3>Questions</h3>
          <ul>
            {filteredQuizzes.map((quiz) => (
              <li key={quiz.id}>
                <h4>{quiz.question.replace(/^<p>|<\/p>$/g, '')}</h4>
                <p>Marks: {quiz.marks} | Time Limit: {quiz.timeLimit} minutes</p>
                <ul>
                  {quiz.options.map((option, index) => (
                    <li key={index}>
                      <label>
                        <input
                          type="radio"
                          name={quiz.id}
                          value={option}
                          checked={userAnswers[quiz.id] === option}
                          onChange={() => handleAnswerChange(quiz.id, option)}
                        />
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>

          <button onClick={handleSubmit}>Submit Quiz</button>
        </div>
      )}
    </div>
  );
};

export default Quiz;
