import React, { useState, useEffect, memo } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust import path as needed
import './Courses.css';

const CourseItem = memo(({ course, isExpanded, onClick }) => (
  <li className="course-item">
    <button
      onClick={() => onClick(course.id)}
      className={`course-title ${isExpanded ? 'expanded' : ''}`}
      aria-expanded={isExpanded}
    >
      {course.serialNo}. {course.title}
    </button>
    {isExpanded && (
      <div className="course-description">{course.description}</div>
    )}
  </li>
));

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [expandedCourseId, setExpandedCourseId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesCollection = collection(db, 'courses');
        const coursesSnapshot = await getDocs(coursesCollection);

        if (coursesSnapshot.empty) {
          console.log('No courses found');
          setCourses([]);
          return;
        }

        const coursesList = coursesSnapshot.docs.map((doc, index) => ({
          id: doc.id,
          serialNo: index + 1,
          ...doc.data(),
        }));
        setCourses(coursesList);
      } catch (err) {
        console.error('Error fetching courses:', err);
        setError('Failed to load courses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  const handleTitleClick = (id) => {
    setExpandedCourseId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="courses">
      <h1>Courses</h1>
      {loading && <p className="loading">Loading courses...</p>}
      {error && <p className="error">{error}</p>}
      {!loading && !error && (
        <ul className="courses-list" aria-live="polite">
          {courses.length === 0 ? (
            <li>No courses available</li>
          ) : (
            courses.map((course) => (
              <CourseItem
                key={course.id}
                course={course}
                isExpanded={expandedCourseId === course.id}
                onClick={handleTitleClick}
              />
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default Courses;
